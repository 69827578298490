import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowIcon,
  ButtonBlur,
  ButtonEllipseColorOne,
  ButtonEllipseColorTwo,
  CardinProfile,
  CustomizedButtonBackgroundDiv,
  CustomizedButtonsContainer,
  EllipseCardOne,
  EllipseCardTwo,
  EllipseOuterContainer,
  FooterDiv,
  HeadingsFooter,
  Icons,
  IconsAndNumber,
  LogoBigFooter,
  LogoFooter,
  MainFooterHeading,
  ProfileBlur,
  ProfileCard,
  ProfileContainer,
  ProfileLogos,
  SaveContact,
  SecondaryFooterHeading,
  ShareProfileButtoninProfile,
  SocialDetails,
  SocialIcons,
  SocialMediaProfile,
} from "./Profile.styled";
import {
  BorderDash,
  CardDescription,
  CardDetails,
  CardHeading,
  CardSocialMedia,
  CustomLogo,
  ImageContact,
  ImageDash,
  LogoCard,
} from "../MyCard/MyCard.styled";
import Whatsapp from "../../assets/whatsapp.svg";
import ImageCard from "../../assets/MyImage.png";
import CustomerLogo from "../../assets/siemens.svg";
import LogoSmall from "../../assets/logosmall.svg";
import Facebook from "../../assets/Group1.svg";
import LinkedIn from "../../assets/Group2.svg";
import Twitter from "../../assets/Group3.svg";
import Instagram from "../../assets/Group4.svg";
import Email from "../../assets/emailButton.svg";
import Website from "../../assets/websiteButton.svg";
import Phone from "../../assets/phone.svg";
import Border from "../../assets/border.png";


const Profile = ({
  background2,
  button1,
  button2,
  logo,
  background1,
  option,
  contenttext,
  buttontext,
  userData,
  linkedin,
  instagram,
  twitter,
  facebook,
}) => {
  const defaultFacebookUrl = "https://www.facebook.com/";
  const defaultLinkedInUrl = "https://linkedin.com/";
  const defaultInstagramUrl = "https://www.instagram.com/";
  const defaultTwitterUrl = "https://twitter.com/";

  let linkedinProfileUrl = defaultLinkedInUrl;
  let instagramProfileUrl = defaultInstagramUrl;
  let twitterProfileUrl = defaultTwitterUrl;
  let facebookProfileUrl = defaultFacebookUrl;

  if (userData) {
    if (userData.socialMedia && userData.socialMedia.linkedin) {
      linkedinProfileUrl = userData.socialMedia.linkedin;
    } else if (linkedin) {
      linkedinProfileUrl = linkedin;
    }

    if (userData.socialMedia && userData.socialMedia.instagram) {
      instagramProfileUrl = userData.socialMedia.instagram;
    } else if (instagram) {
      instagramProfileUrl = instagram;
    }

    if (userData.socialMedia && userData.socialMedia.twitter) {
      twitterProfileUrl = userData.socialMedia.twitter;
    } else if (twitter) {
      twitterProfileUrl = twitter;
    }

    if (userData.socialMedia && userData.socialMedia.facebook) {
      facebookProfileUrl = userData.socialMedia.facebook;
    } else if (facebook) {
      facebookProfileUrl = facebook;
    }
  }

  const navigate = useNavigate()
  const params = useParams()
  const handleProfileView = () => {
    navigate(`/profile/${params.id}`);
  };



  return (
    <>
      {userData && (
        <ProfileContainer>
          {/* CARD */}
          <ProfileCard>
            <EllipseOuterContainer>
              <EllipseCardOne
                color={
                  background1
                    ? background1
                    : userData.backgroundColor1
                    ? userData.backgroundColor1
                    : "#25CBB6"
                }
              ></EllipseCardOne>
              <EllipseCardTwo
                color={
                  option === "gradient"
                    ? background2
                      ? background2
                      : userData.backgroundColor2
                      ? userData.backgroundColor2
                      : "#7ED321"
                    : option === "solid"
                    ? background1
                      ? background1
                      : userData.backgroundColor1
                      ? userData.backgroundColor1
                      : "#25CBB6"
                    : "#25CBB6"
                }
              ></EllipseCardTwo>
            </EllipseOuterContainer>
            <ProfileBlur>
              <CardinProfile>
                <ProfileLogos>
                  <LogoCard src={LogoSmall} />
                  <CustomLogo
                    src={
                      userData && userData.logo ? userData.logo : CustomerLogo
                    }
                  />
                </ProfileLogos>
                <ImageContact>
                  <ImageDash
                    src={
                      userData && userData.image ? userData.image : ImageCard
                    }
                    alt="This is an image"
                  />
                  <BorderDash src={Border} alt="This is the order" />

                  <CardDetails>
                    <CardHeading color={contenttext ? contenttext : "#202020"}>
                      {userData && userData.firstname && userData.lastname
                        ? `${userData.firstname} ${userData.lastname}`
                        : "Your Name"}
                    </CardHeading>
                    <CardDescription
                      color={contenttext ? contenttext : "#202020"}
                    >
                      {userData && userData.designation
                        ? `${userData.designation}`
                        : "Designation"}
                    </CardDescription>
                    <CardSocialMedia>
                      <a
                        href={facebookProfileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <SocialMediaProfile src={Facebook} alt="social" />
                      </a>
                      <a
                        href={linkedinProfileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <SocialMediaProfile src={LinkedIn} alt="social" />
                      </a>
                      <a
                        href={twitterProfileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <SocialMediaProfile src={Twitter} alt="social" />
                      </a>
                      <a
                        href={instagramProfileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <SocialMediaProfile src={Instagram} alt="social" />
                      </a>
                    </CardSocialMedia>
                  </CardDetails>
                </ImageContact>
                <ShareProfileButtoninProfile onClick={handleProfileView}>
                  VIEW PROFILE
                </ShareProfileButtoninProfile>
              </CardinProfile>
            </ProfileBlur>
          </ProfileCard>

          {/* BUTTONS */}
          <CustomizedButtonsContainer>
            <CustomizedButtonBackgroundDiv>
              <ButtonEllipseColorOne
                color={
                  background1
                    ? background1
                    : userData.backgroundColor1
                    ? userData.backgroundColor1
                    : "#25CBB6"
                }
              ></ButtonEllipseColorOne>
              <ButtonEllipseColorTwo
                color={
                  option === "gradient"
                    ? background2
                      ? background2
                      : userData.backgroundColor2
                      ? userData.backgroundColor2
                      : "#7ED321"
                    : option === "solid"
                    ? background1
                      ? background1
                      : userData.backgroundColor1
                      ? userData.backgroundColor1
                      : "#25CBB6"
                    : "#25CBB6"
                }
              ></ButtonEllipseColorTwo>
            </CustomizedButtonBackgroundDiv>
            <ButtonBlur>
              <Icons>
                <IconsAndNumber>
                  <SocialIcons src={Whatsapp} alt="whatsapp" />
                  <SocialDetails color={contenttext ? contenttext : "#202020"}>
                    {userData && userData.whatsappNo
                      ? `${userData.whatsappNo}`
                      : "+971 56 00 000"}
                  </SocialDetails>
                </IconsAndNumber>
                <ArrowIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      fill={
                        button1
                          ? button1
                          : userData.buttonColor1
                          ? userData.buttonColor1
                          : "#25CBB6"
                      }
                    />
                    <path d="M6.5 5L9.5 8L6.5 11" stroke="white" />
                    <defs>
                      <linearGradient
                        id="paint0_linear_208_14208"
                        x1="8"
                        y1="-4.5"
                        x2="6.5"
                        y2="24"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#25CBB6" />
                        <stop offset="1" stop-color="#045EE1" />
                      </linearGradient>
                    </defs>
                  </svg>
                </ArrowIcon>
              </Icons>
            </ButtonBlur>
          </CustomizedButtonsContainer>

          <CustomizedButtonsContainer>
            <CustomizedButtonBackgroundDiv>
              <ButtonEllipseColorOne
                color={
                  background1
                    ? background1
                    : userData.backgroundColor1
                    ? userData.backgroundColor1
                    : "#25CBB6"
                }
              ></ButtonEllipseColorOne>
              <ButtonEllipseColorTwo
                color={
                  option === "gradient"
                    ? background2
                      ? background2
                      : userData.backgroundColor2
                      ? userData.backgroundColor2
                      : "#7ED321"
                    : option === "solid"
                    ? background1
                      ? background1
                      : userData.backgroundColor1
                      ? userData.backgroundColor1
                      : "#25CBB6"
                    : "#25CBB6"
                }
              ></ButtonEllipseColorTwo>
            </CustomizedButtonBackgroundDiv>
            <ButtonBlur>
              <Icons>
                <IconsAndNumber>
                  <SocialIcons src={Phone} alt="whatsapp" />
                  <SocialDetails color={contenttext ? contenttext : "#202020"}>
                    {userData && userData.phoneNo
                      ? `${userData.phoneNo}`
                      : "+971 56 00 000"}
                  </SocialDetails>
                </IconsAndNumber>
                <ArrowIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      fill={
                        button1
                          ? button1
                          : userData.buttonColor1
                          ? userData.buttonColor1
                          : "#25CBB6"
                      }
                    />
                    <path d="M6.5 5L9.5 8L6.5 11" stroke="white" />
                    <defs>
                      <linearGradient
                        id="paint0_linear_208_14208"
                        x1="8"
                        y1="-4.5"
                        x2="6.5"
                        y2="24"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#25CBB6" />
                        <stop offset="1" stop-color="#045EE1" />
                      </linearGradient>
                    </defs>
                  </svg>
                </ArrowIcon>
              </Icons>
            </ButtonBlur>
          </CustomizedButtonsContainer>

          <CustomizedButtonsContainer>
            <CustomizedButtonBackgroundDiv>
              <ButtonEllipseColorOne
                color={
                  background1
                    ? background1
                    : userData.backgroundColor1
                    ? userData.backgroundColor1
                    : "#25CBB6"
                }
              ></ButtonEllipseColorOne>
              <ButtonEllipseColorTwo
                color={
                  option === "gradient"
                    ? background2
                      ? background2
                      : userData.backgroundColor2
                      ? userData.backgroundColor2
                      : "#7ED321"
                    : option === "solid"
                    ? background1
                      ? background1
                      : userData.backgroundColor1
                      ? userData.backgroundColor1
                      : "#25CBB6"
                    : "#25CBB6"
                }
              ></ButtonEllipseColorTwo>
            </CustomizedButtonBackgroundDiv>
            <ButtonBlur>
              <Icons>
                <IconsAndNumber>
                  <SocialIcons src={Website} alt="whatsapp" />
                  <SocialDetails color={contenttext ? contenttext : "#202020"}>
                    {userData && userData.email
                      ? `${userData.company}`
                      : "example@gmail.com"}
                  </SocialDetails>
                </IconsAndNumber>
                <ArrowIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      fill={
                        button1
                          ? button1
                          : userData.buttonColor1
                          ? userData.buttonColor1
                          : "#25CBB6"
                      }
                    />
                    <path d="M6.5 5L9.5 8L6.5 11" stroke="white" />
                    <defs>
                      <linearGradient
                        id="paint0_linear_208_14208"
                        x1="8"
                        y1="-4.5"
                        x2="6.5"
                        y2="24"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#25CBB6" />
                        <stop offset="1" stop-color="#045EE1" />
                      </linearGradient>
                    </defs>
                  </svg>
                </ArrowIcon>
              </Icons>
            </ButtonBlur>
          </CustomizedButtonsContainer>

          <CustomizedButtonsContainer>
            <CustomizedButtonBackgroundDiv>
              <ButtonEllipseColorOne
                color={
                  background1
                    ? background1
                    : userData.backgroundColor1
                    ? userData.backgroundColor1
                    : "#25CBB6"
                }
              ></ButtonEllipseColorOne>
              <ButtonEllipseColorTwo
                color={
                  option === "gradient"
                    ? background2
                      ? background2
                      : userData.backgroundColor2
                      ? userData.backgroundColor2
                      : "#7ED321"
                    : option === "solid"
                    ? background1
                      ? background1
                      : userData.backgroundColor1
                      ? userData.backgroundColor1
                      : "#25CBB6"
                    : "#25CBB6"
                }
              ></ButtonEllipseColorTwo>
            </CustomizedButtonBackgroundDiv>
            <ButtonBlur>
              <Icons>
                <IconsAndNumber>
                  <SocialIcons src={Email} alt="whatsapp" />
                  <SocialDetails color={contenttext ? contenttext : "#202020"}>
                    {userData && userData.email
                      ? `${userData.email}`
                      : "example@gmail.com"}
                  </SocialDetails>
                </IconsAndNumber>
                <ArrowIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <circle
                      cx="8"
                      cy="8"
                      r="8"
                      fill={
                        button1
                          ? button1
                          : userData.buttonColor1
                          ? userData.buttonColor1
                          : "#25CBB6"
                      }
                    />
                    <path d="M6.5 5L9.5 8L6.5 11" stroke="white" />
                    <defs>
                      <linearGradient
                        id="paint0_linear_208_14208"
                        x1="8"
                        y1="-4.5"
                        x2="6.5"
                        y2="24"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#25CBB6" />
                        <stop offset="1" stop-color="#045EE1" />
                      </linearGradient>
                    </defs>
                  </svg>
                </ArrowIcon>
              </Icons>
            </ButtonBlur>
          </CustomizedButtonsContainer>
          <SaveContact
            colorOne={
              button1
                ? button1
                : userData.buttonColor1
                ? userData.buttonColor1
                : "#25CBB6"
            }
            colorTwo={
              option === "gradient"
                ? button2
                  ? button2
                  : userData.buttonColor2
                  ? userData.buttonColor2
                  : "#7ED321"
                : option === "solid"
                ? button1
                  ? button1
                  : userData.buttonColor1
                  ? userData.buttonColor1
                  : "#25CBB6"
                : "#25CBB6"
            }
            color={buttontext ? buttontext : "#fff"}
          >
            SAVE CONTACT
          </SaveContact>
          <FooterDiv>
            <LogoFooter>
              <LogoBigFooter src={LogoSmall} />
            </LogoFooter>
            <HeadingsFooter>
              <MainFooterHeading>GET YOUR OWN</MainFooterHeading>
              <SecondaryFooterHeading>SMART CARD</SecondaryFooterHeading>
            </HeadingsFooter>
          </FooterDiv>
        </ProfileContainer>
      )}
    </>
  );
};

export default Profile;
