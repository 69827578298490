import React from "react";
import Navbar from "../components/Navbar/Navbar";
import MyCard from "../components/MyCard/MyCard";
import Footer from "../components/Footer/Footer";

const Dashboard = () => {
  return (
    <>
      <Navbar display="block" />
      <MyCard />
      <Footer />
    </>
  );
};

export default Dashboard;
