import React from 'react'
import { ProfilePageContainer } from '../components/Profile/Profile.styled'
import UserProfile from '../components/Profile/UserProfile';

const ProfilePage = () => {


  return (
    <ProfilePageContainer>
      <UserProfile  />
    </ProfilePageContainer>
  )
}

export default ProfilePage