import styled from "styled-components";

export const ProfileContainer = styled.div`
  width: 100%;
  margin: auto;
`;

export const ProfileCard = styled.div`
  position: relative;
  height: auto;
  width: auto;
  overflow-y: hidden;
  padding-bottom: 18px;
`;

export const EllipseCardOne = styled.div`
  width: 50%;
  height: 50%;
  margin: auto;
  border-radius: 0%;
  background-color: ${(props) => props.color};
`;

export const EllipseCardTwo = styled.div`
  width: 50%;
  height: 50%;
  margin: auto;
  border-radius: 0%;
  background-color: ${(props) => props.color};
`;

export const EllipseOuterContainer = styled.div`
  width: 100%;
  height: 550px;
  display: flex;
  position: absolute;
  top: -139px;
  
  @media only screen and (max-width: 1024px) {
    height: 510px;
    top: -126px;
  }

  @media only screen and (max-width: 400px) {
    height: 465px;
    top: -116px;
  }
  @media only screen and (max-width: 350px) {
    height: 425px;
    top: -106px;
  }
`;

export const ProfileBlur = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  border: 0.4px solid #f3f3f3;
  background: rgba(255, 255, 255, 0.64);
  backdrop-filter: blur(47.265304565429688px);
  margin: auto;
  align-items: flex-start;
  padding: 20px;
  @media only screen and (max-width: 350px) {
    padding: 10px;
  }
`;

export const ShareProfileButtoninProfile = styled.button`
  font-family: Outfit;
  font-size: 13.996px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: white;
  padding: 10px 25px;
  color: #045ee1;
  border: none;
  text-align: center;
  cursor: pointer;
  @media only screen and (max-width: 400px) {
    padding: 8px 10px;
    font-size: 12px;
  }
`;

export const ProfileLogos = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const BorderAroundImage = styled.div`
  width: 80px;
  height: 105px;
  position: absolute;
  margin-left: 40px;
`;

export const SocialMediaProfile = styled.img`
  height: 37px;
  width: 37px;
  cursor: pointer;
  @media only screen and (max-width: 400px) {
    height: 27px;
    width: 27px;
  }
`;

export const CardinProfile = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  align-items: flex-start;
  gap: 25px;
  @media only screen and (max-width: 400px) {
    gap: 15px;
  }
`;

export const CustomizedButtonsContainer = styled.div`
  position: relative;
  height: auto;
  width: auto;
  overflow-y: hidden;
  margin-bottom: 10px;
`;

export const CustomizedButtonBackgroundDiv = styled.div`
  width: 100%;
  height: 312px;
  display: flex;
  position: absolute;
  top: -111px;
`;

export const ButtonEllipseColorOne = styled.div`
  width: 50%;
  height: 50%;
  margin: auto;
  border-radius: 0%;
  background-color: ${(props) => props.color};
`;

export const ButtonEllipseColorTwo = styled.div`
  width: 50%;
  height: 50%;
  margin: auto;
  background-color: ${(props) => props.color};
`;

export const ButtonEllipseTwo = styled.div`
  display: flex;
  position: absolute;
  top: 74%;
  right: 0%;
  left: 0%;
  bottom: 0%;
  margin: auto;
  @media only screen and (max-width: 1024px) and (min-width: 350px) {
    top: 75%;
  }
  @media only screen and (max-width: 350px) {
    top: 65%;
  }
`;

export const ButtonEllipseThree = styled.div`
  display: flex;
  position: absolute;
  top: 87%;
  right: 0%;
  left: 0%;
  bottom: 0%;
  margin: auto;
  @media only screen and (max-width: 1024px) and (min-width: 350px) {
    top: 85%;
  }
  @media only screen and (max-width: 350px) {
    top: 75%;
  }
`;

export const ButtonEllipseFour = styled.div`
  display: flex;
  position: absolute;
  top: 99%;
  right: 0%;
  left: 0%;
  bottom: 0%;
  margin: auto;
  @media only screen and (max-width: 1024px) and (min-width: 350px) {
    top: 97%;
  }
  @media only screen and (max-width: 350px) {
    top: 87%;
  }
`;

export const ButtonBlur = styled.div`
  display: flex;
  width: 100%;
  border: 0.4px solid #f3f3f3;
  background: rgba(255, 255, 255, 0.64);
  backdrop-filter: blur(30.265304565429688px);
  margin: auto;
  align-items: flex-start;
  padding: 10px 0px;
`;

export const Icons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
`;

export const IconsAndNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

export const SocialIcons = styled.img`
  height: 35px;
  width: 35px;
`;

export const SocialDetails = styled.h1`
  color: ${props => props.color};
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const ArrowIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfilePageContainer = styled.div`
  display: flex;
  width: 50%;
  padding: 20px;
  margin: auto;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const SaveContact = styled.button`
  border: 0.4px solid #f3f3f3;
  background: linear-gradient(93deg, ${props => props.colorOne} 4.24%, ${props => props.colorTwo} 90.89%);
  backdrop-filter: blur(18.265304565429688px);
  color: ${props => props.color};
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  padding: 12px 0px;
  line-height: normal;
  cursor: pointer;
`;

export const FooterDiv = styled.div`
  display: flex;
  width: 100%;
  height: 140px;
  background-color: black;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  margin-top: 18px;
`;

export const LogoFooter = styled.div`
  height: auto;
`;

export const HeadingsFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 05px;
`;

export const LogoBigFooter = styled.img`
  height: 100px;
  height: 98.1px;
`;

export const MainFooterHeading = styled.h1`
  color: #fff;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const SecondaryFooterHeading = styled.h1`
  font-family: Outfit;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(92deg, #25cbb6 35.93%, #045ee1 89.42%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
