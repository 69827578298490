import styled from "styled-components";
import BackgroundImage from "../../assets/background.png";
import Box from "@mui/material/Box";

export const LoginContainerFirst = styled.div`
  display: flex;
  width: 85%;
  margin: auto;
  height: 100vh;
  align-items: center;
  overflow: hidden;
  margin-top: 80px;
  @media only screen and (min-width: 1900px) {
    width: 1160px;
  }
  @media only screen and (max-width: 1240px) {
    margin-top: 0px;
  }

  @media only screen and (max-width: 400px) {
    margin-top: 50px;
    width: 90%;
  }
`;

export const BoxStyle = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
  border-radius: 20px;
`;


export const LoginMainContainer = styled.div`
  justify-content: space-between;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 483px;
  width: 100%;
`;

export const LoginBlur = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.64);
  backdrop-filter: blur(50px);
`;

export const LoginCredentials = styled.div`
  display: flex;
  flex-direction: column;
  padding: 70px 50px;
  width: 120%;

  @media only screen and (min-width: 1300px) and (max-width: 1500px) {
    padding: 70px 40px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1300px) {
    padding: 70px 30px;
    width: 150%;
  }
  @media only screen and (max-width: 1024px) {
    align-items: center;
    padding: 70px 20px;
    width: 70%;
    margin: auto;
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
    padding: 70px 10px;
  }
`;

export const LoginImageMainDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 70px 50px;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  @media only screen and (min-width: 1300px) and (max-width: 1500px) {
    padding: 70px 40px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1300px) {
    padding: 70px 30px;
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const LoginImage = styled.img`
  height: 406px;
  width: 403px;
  @media only screen and (min-width: 1024px) and (max-width: 1500px) {
    height: 320px;
    width: 320px;
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const HeadingSecondary = styled.h1`
  color: #262626;
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  padding-left: 10px;
`;

export const HeadingPrimary = styled.div`
  padding-left: 10px;
  font-family: Outfit;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(
    92deg,
    rgba(0, 0, 0, 1),
    rgba(37, 203, 182, 1),
    rgba(4, 94, 225, 1)
  );
  background-clip: text;
  -webkit-background-clip: text; /* This will clip the text to the gradient */
  -webkit-text-fill-color: transparent; /* Make the text transparent to show the gradient */
  @media only screen and (max-width: 400px) {
    font-size: 30px;
  }
`;

export const LoginFormDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 45px 10px;
  width: 80%;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const LoginEmailInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  border: 1px solid #fff;
  color: #000; /* Text color for input text */
  background-color: #fff; /* Input background color */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.15px; /* 119.687% */
  letter-spacing: 0.16px;
  border-radius: 12px;
  &::placeholder {
    color: var(--Gray-3, #828282);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.15px; /* 119.687% */
    letter-spacing: 0.16px;
  }

  &:focus {
    border-color: #045ee1 !important;
    box-shadow: 0px 0px 0px 5px rgba(4, 94, 225, 0.17);
    -webkit-box-shadow: 0px 0px 0px 5px rgba(4, 94, 225, 0.17);
    outline: none !important;
    appearance: none;
    -webkit-appearance: none;
  }
`;

export const SubmitButton = styled.button`
  border-radius: 11px;
  border: none;
  background: #045ee1;
  justify-content: center;
  align-items: center;
  color: var(--white, #fff);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 10px 0px;
  cursor: pointer;
`;
