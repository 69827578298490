import React, { useEffect, useState } from 'react'
import logo from '../assets/logo.svg'
import user from '../assets/user.png'
import logotitle from '../assets/slogan.svg'
import bord from '../assets/border.png'
import facebook from '../assets/facebook.svg'
import linkedin from '../assets/linkedin.svg'
import twitter from '../assets/twitter.svg'
import instagram from '../assets/instagram.svg'
import iconw from '../assets/whatsappicon.png'
import iconp from '../assets/phoneicon.png'
import icong from '../assets/globeicon.png'
import iconm from '../assets/mailicon.png'
import card from '../assets/getcard.png'
import { FaChevronCircleRight } from "react-icons/fa";
import { useParams } from 'react-router-dom'
import vCard from 'vcards-js';
import axios from 'axios'


const Details = () => {
  const [orderData, setOrderData] = useState('');
  const [img, setImg] = useState('')
  const id = useParams()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const orderKey = id.id; // Replace with the actual order key or parameter
        const response = await axios.get(`http://3.73.173.215/api/userdetail/${orderKey}`);
        setOrderData(response.data);
        if (response.data && response.data.document && response.data.document.data) {
          const base64Image = `data:${response.data.document.contentType};base64,${response.data.document.data.toString('base64')}`;
          setImg(base64Image);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  console.log(orderData)

  const profileData = {
    firstName:'jkojk',
    lastName:'jhgj',
    designation:'sdvgfv',
    company:'dsfdf',
    homePhone:'455456',
    workPhone:'88555'
  }
  const handleSaveContact = () => {
  
  
      const vcf = vCard();
      vcf.firstName = orderData.firstname;
vcf.lastName = orderData.lastname;
vcf.title = orderData.designation;
vcf.organization = orderData.company;
// vcf.logo.embedFromFile(img);
vcf.homePhone = orderData.phoneNo;
vcf.workPhone = orderData.whatsappNo;
vcf.url = 'https://www.softversum.com';
// vcf.socialUrls['facebook'] = orderData.socialMedia.facebook;
// vcf.socialUrls['linkedIn'] = orderData.socialMedia.linkedIn;
// vcf.socialUrls['twitter'] = orderData.socialMedia.twitter;
// vcf.socialUrls['instagram'] = orderData.socialMedia.instagram;
  
      const vcfContent = vcf.getFormattedString();
  
      if (navigator.contacts && navigator.contacts.save) {
        const blob = new Blob([vcfContent], { type: 'text/vcard' });
        const file = new File([blob], 'contact.vcf', { type: 'text/vcard' });
  
        const contactData = new window.Contact();
        contactData.files = [file];
  
        navigator.contacts.save(contactData);
      } else if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
        const dataURI = `data:text/vcard;charset=utf-8,${encodeURIComponent(vcfContent)}`;
        window.open(dataURI);
      } else {
        // Handle unsupported platform or device
        console.log('Opening contact information is not supported on this platform.');
      }
    
    };

 
  return (
    <div className='detail-container'>
        <div className='detail-logo-container'>
            <img src={logo} alt=''/>
            <img className='slogan' src={logotitle} alt=''/>
        </div>
        <div className='detail-background-image'>
        <div className='detail-content-container'>
            <img className='detail-image' src={user} alt=''/>
            <img className='border' src={bord} alt=''/>
            <div>
                <h1 className='detail-title'>{orderData.firstname}</h1>
                <h3 className='detail-designation'>Entrepreneur & Investor</h3>
                <div className='detail-sociallinks'>
                <a href='www.facebook.com'><img src={facebook} alt=''/></a>
                <a href='www.linkedin.com'><img src={linkedin} alt=''/></a>
                <a href='www.twitter.com'><img src={twitter} alt=''/></a>
                <a href='www.instagram.com'><img src={instagram} alt=''/></a>

                </div>
            </div>
        </div>
        <div className='share-button'>
           <h3> SHARE PROFILE </h3>
            </div>
        </div>
        <a href='https://wa.link/loqz5h'>
        <div className='detail-social-detail'>
        <img src={iconw} alt=''/>
        <h5>+49 172 8129789</h5>
        <FaChevronCircleRight color='#48AAAD' style={{marginLeft:'auto'}} size={25}/>
        </div></a>
        <a href='tel:+491728129789'>
        <div className='detail-social-detail'>
        <img src={iconp} alt=''/>
        <h5>+49 172 8129789</h5>
        <FaChevronCircleRight color='#48AAAD' style={{marginLeft:'auto'}} size={25}/>
        </div>
        </a>
        <a href='www.softversum.com'>
        <div className='detail-social-detail'>
        <img src={icong} alt=''/>
        <h5>www.softversum.com</h5>
        <FaChevronCircleRight color='#48AAAD' style={{marginLeft:'auto'}}size={25}/>
        </div>
        </a>
        <a href='mailto:juergen.pernegger@softversum.com'>
        <div className='detail-social-detail'>
        <img src={iconm} alt=''/>
        <h5>juergen.pernegger@softversum.com</h5>
        <FaChevronCircleRight color='#48AAAD' style={{marginLeft:'auto'}}size={25}/>
        </div>
        </a>
        <button className='save-contact-button' onClick={handleSaveContact} download="contact.vcf">SAVE CONTACT</button>
        <img className='get-card' src={card} alt=''/>
    </div>
  )
}

export default Details