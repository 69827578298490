import styled from "styled-components";
import EllipseOne from "../../assets/ellipse.png";
import EllipseTwo from "../../assets/ellipse1.png";
import BackgroundCard from "../../assets/dash.png";
import ProfileBackground from "../../assets/profile.png";

export const DashboardContainerFirst = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: auto;
  align-items: center;
  overflow-y: hidden;
  justify-content: space-between;
  padding-top: 90px;
  padding-bottom: 90px;
  @media only screen and (max-width: 500px) {
    padding-top: 50px;
  }
`;

export const EllipseDiv = styled.div`
  display: flex;
  position: absolute;
  top: 0%;
  right: 0%;
  left: 0%;
  bottom: 0%;
  width: 100%;
  margin: auto;
  height: auto;
`;

// green ellipse
export const DashboardImageFirst = styled.div`
  background-image: url(${EllipseOne});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 60%;
`;

// blue ellipse
export const DashboardImageTwo = styled.div`
  background-image: url(${EllipseTwo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 60%;
`;

export const DashboardBlur = styled.div`
  display: flex;
  height: auto;
  width: 80%;
  background: rgba(255, 255, 255, 0.64);
  backdrop-filter: blur(50px);
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 70px;
  padding-top: 70px;
  @media only screen and (max-width: 1100px) {
    width: 80%;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

// The div which comes inside the blur div and it is the first div having all the contents

export const InnerFirstDiv = styled.div`
  height: auto;
  width: 100%;
  margin-top: 50px;
  margin: auto;
  @media only screen and (min-width: 1900px) {
    width: 1200px;
  }
`;

// The div where we have two div, one is the tab second is the profile in the column direction

export const InnerSecondDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: auto;
`;

// THe top of the div in column direction that has tab

export const TabMainDiv = styled.div`
  width: 100%;
  margin: 0 auto;
  min-height: 62px;
  justify-content: center;
  align-items: center;
`;

// This is the inner div of the tab where buttons are placed
export const TabInnverdiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 450px;
  padding: 6px;
  background-color: #fff;
  margin: auto;
  border-radius: 20px;
  overflow: auto;
  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;

export const TabButton = styled.button`
  border-radius: 14px;
  text-align: center;
  background: ${(props) => (props.active ? "#045EE1" : "#fff")};
  padding: 12px 40px;
  border: none;
  color: ${(props) => (props.active ? "white" : "#000")};
  font-family: Outfit;
  font-size: 14.832px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.371px;
  &:active,
  &:focus {
    background: #045ee1;
    color: white;
    outline: none;
  }
  @media only screen and (max-width: 500px) {
    padding: 10px 25px;
  }
`;

// It has two more divs inside it in row direction
export const CardAndDetailsDiv = styled.div`
  display: flex;
  justify-content: center;
  height: auto;
  width: 85%;

  @media only screen and (min-width: 1300px) and (max-width: 1450px) {
    width: 90%;
  }
  @media only screen and (max-width: 1300px) {
    width: 100%;
    padding: 0 20px;
    justify-content: space-between;
  }
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const DetailsOnly = styled.div`
  width: 55%;
  padding: 30px;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url(${ProfileBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media only screen and (max-width: 1100px) {
    width: 100%;
    padding: 20px 10px 20px 10px;
  }
  @media only screen and (max-width: 1100px) and (min-width: 1240px) {
    width: 53%;
  }
`;

export const DetailsForBranding = styled.div`
  width: 100%;
  padding: 30px;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url(${ProfileBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media only screen and (max-width: 1100px) {
    width: 100%;
    padding: 20px;
  }
  @media only screen and (max-width: 1100px) and (min-width: 1240px) {
    width: 53%;
  }
`;

export const SubmitButton = styled.button`
  padding: 9.112px 18.223px;
  width: 100%;
  color: white;
  border: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10.468px;
  background: #045ee1;
  cursor: pointer;
`;

export const YourDetails = styled.h1`
  color: #262626;
  font-family: Outfit;
  font-size: 17.129px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
`;

export const ColorDetails = styled.h1`
  color: #262626;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
`;

export const ColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const DetailDivMain = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px;
  row-gap: 20px;
  padding-left: 10px;
  padding-right: 10px;
  @media only screen and (max-width: 1100px) {
    padding-left: 05px;
    padding-right: 05px;
  }
`;

export const SmallInputField = styled.input`
  background-color: white;
  color: #000000;
  font-family: Montserrat;
  font-size: 15.226px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.223px;
  width: 48.5%;
  height: 43px;
  border: none;
  border-radius: 11px;
  padding-left: 15px;
  &::placeholder {
    color: #303030;
    font-size: 13px;
  }
  &:focus {
    border-color: #045ee1 !important;
    box-shadow: 0px 0px 0px 5px rgba(4, 94, 225, 0.17);
    -webkit-box-shadow: 0px 0px 0px 5px rgba(4, 94, 225, 0.17);
    outline: none !important;
    appearance: none;
    -webkit-appearance: none;
  }
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const LargeInputField = styled.input`
  background-color: white;
  color: #000000;
  font-family: Montserrat;
  font-size: 15.226px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.223px;
  width: 100%;
  height: 43px;
  border: none;
  border-radius: 11px;
  padding-left: 15px;
  &::placeholder {
    color: #303030;
    font-size: 13px;
  }
  &:focus {
    border-color: #045ee1 !important;
    box-shadow: 0px 0px 0px 5px rgba(4, 94, 225, 0.17);
    -webkit-box-shadow: 0px 0px 0px 5px rgba(4, 94, 225, 0.17);
    outline: none !important;
    appearance: none;
    -webkit-appearance: none;
  }
`;

export const PersonalInputDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const UpdateButton = styled.button`
  border-radius: 9px;
  background: #045ee1;
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Outfit;
  font-size: 14.832px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.371px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  @media only screen and (max-width: 500px) {
    padding: 10px 10px;
  }
`;

export const PersonalInputField = styled.input`

  background-color: white;
  color: #000000;
  font-family: Montserrat;
  font-size: 15.226px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.223px;
  width: 78%;
  height: 43px;
  border: none;
  border-radius: 11px;
  padding-left: 15px;
  &::placeholder {
    color: #303030;
    font-size: 13px;
  }
  &:focus {
    outline: none !important;
    appearance: none;
    -webkit-appearance: none;
  }
  @media only screen and (max-width: 1240px) {
    width: 75%;
  }
`;

export const CustomFileInput = styled.label`
  display: block;
  height: 74px;
  width: 100%;
  background-color: white;
  color: #000000;
  font-family: Montserrat;
  font-size: 15.226px;
  font-style: normal;
  font-weight: 400;
  border: none;
  border-radius: 11px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const ProfileLogoandImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const LogoHeading = styled.h1`
  color: #000;
  font-family: Outfit;
  font-size: 14.3px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const CardOnly = styled.div`
  width: 45%;
  @media only screen and (max-width: 1240px) {
    width: 43%;
  }
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const CardMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 422px;
  height: 263px;
  background-image: url(${BackgroundCard});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  gap: 25px;
  padding: 15px 25px;
  @media only screen and (max-width: 1100px) {
    padding: 15px 10px;
  }
`;

export const CardLogo = styled.div`
  width: 100%;
`;

export const LogoCard = styled.img`
  width: 31px;
  height: 28px;
  text-align: left;
  display: block;
`;

export const CustomLogo = styled.img`
  width: 72px;
  height: 17px;
  text-align: left;
  display: block;
`;

export const ImageContact = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
`;

export const ImageDash = styled.img`
  width: 104px;
  height: 104px;
  text-align: left;
  display: block;
`;

export const BorderDash = styled.img`
  width: 80px;
  height: 120px;
  position: absolute;
  margin-left: 35px;
`;

export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CardHeading = styled.h1`
  color: ${(props) => props.color};
  font-family: Outfit;
  font-size: 25.61px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  word-wrap: wrap;
  @media only screen and (max-width: 1100px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 350px) {
    font-size: 17px;
  }
`;

export const CardDescription = styled.h1`
  color: ${(props) => props.color};
  font-family: Outfit;
  font-size: 16.297px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: wrap;
  @media only screen and (max-width: 1100px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 350px) {
    font-size: 12px;
  }
`;

export const CardSocialMedia = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding-top: 27px;
  @media only screen and (max-width: 1100px) {
    gap: 10px;
    padding-top: 10px;
  }
`;

export const SocialMedia = styled.img`
  height: 37px;
  width: 37px;
  cursor: pointer;
  @media only screen and (max-width: 380px) {
    height: 30px;
    width: 30px;
  }
`;

export const ShareProfileButton = styled.button`
  font-family: Outfit;
  font-size: 13.996px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(119deg, #045ee1 32.04%, #25cbb6 159.21%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
`;

export const ParagraphyProfile = styled.p`
  color: #303030;
  font-family: Montserrat;
  font-size: 13.23px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.223px;
  letter-spacing: 0.132px;
  text-align: left;
  padding-right: 5px;
  padding-bottom: 30px;
`;

export const ParaDiv = styled.div`
  padding-top: 20px;
`;

export const AccountDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 50%;
  height: auto;
  padding: 30px;
  justify-content: flex-start;
  align-items: flex-start;
  background-image: url(${ProfileBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media only screen and (min-width: 1900px) {
    width: 600px;
  }
  @media only screen and (max-width: 1100px) {
    width: 80%;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

// All CSS for branding

export const ProfileBrandingDiv = styled.div`
  display: flex;
  justify-content: center;
  height: auto;
  width: 85%;
  justify-content: space-between;

  @media only screen and (min-width: 1300px) and (max-width: 1450px) {
    width: 90%;
  }
  @media only screen and (max-width: 1300px) {
    width: 100%;
    padding: 0 20px;
    justify-content: space-between;
  }
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const ProfileOnly = styled.div`
  width: 40%;
  background: rgba(255, 255, 255, 0.64);
  backdrop-filter: blur(35.265304565429688px);
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const FormOnly = styled.div`
  width: 59%;
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const ProfileMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 422px;
  height: auto;
  background-image: url(${BackgroundCard});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  gap: 25px;
  padding: 15px 25px;
  @media only screen and (max-width: 1100px) {
    padding: 15px 10px;
  }
`;

export const BackgroundColorOne = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckboxWrapper = styled.label`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  gap: 10%;
`;

export const WrapperCheck = styled.div`
  display: flex;
  gap: 10px;
`;

export const CheckboxInput = styled.input.attrs({ type: "checkbox" })`
  /* Styles for the checkbox input itself */
  /* You can add your checkbox styles here */
`;

export const LabelMAIN = styled.span`
  color: #202020;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  /* Styles for the label text */
  /* You can add your label text styles here */
`;
