import React, { useEffect, useState } from "react";
import {
  ArrowIcon,
  ButtonBlur,
  ButtonEllipseColorOne,
  ButtonEllipseColorTwo,
  CardinProfile,
  CustomizedButtonBackgroundDiv,
  CustomizedButtonsContainer,
  EllipseCardOne,
  EllipseCardTwo,
  EllipseOuterContainer,
  FooterDiv,
  HeadingsFooter,
  Icons,
  IconsAndNumber,
  LogoBigFooter,
  LogoFooter,
  MainFooterHeading,
  ProfileBlur,
  ProfileCard,
  ProfileContainer,
  ProfileLogos,
  SaveContact,
  SecondaryFooterHeading,
  ShareProfileButtoninProfile,
  SocialDetails,
  SocialIcons,
  SocialMediaProfile,
} from "./Profile.styled";
import {
  BorderDash,
  CardDescription,
  CardDetails,
  CardHeading,
  CardSocialMedia,
  CustomLogo,
  ImageContact,
  ImageDash,
  LogoCard,
} from "../MyCard/MyCard.styled";
import Whatsapp from "../../assets/whatsapp.svg";
import ImageCard from "../../assets/MyImage.png";
import LogoSmall from "../../assets/logosmall.svg";
import Facebook from "../../assets/Group1.svg";
import LinkedIn from "../../assets/Group2.svg";
import Twitter from "../../assets/Group3.svg";
import Instagram from "../../assets/Group4.svg";
import Email from "../../assets/emailButton.svg";
import Website from "../../assets/websiteButton.svg";
import Phone from "../../assets/phone.svg";
import { useParams } from "react-router-dom";
import axios from "axios";
import Border from "../../assets/border.png";


const UserProfile = () => {
  const params = useParams();
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const handleUserData = async () => {
      const userId = params.id;

      try {
        const response = await axios.get(
          `https://api.weconnectify.com/api/userdetail/${userId}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          const userprofile = response.data;
          setUserData(userprofile);
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    handleUserData();
  }, []);
  return (
    <>
      {userData && <ProfileContainer>
        {/* CARD */}
        <ProfileCard>
          <EllipseOuterContainer>
            <EllipseCardOne
              color={
                userData.backgroundColor1
                  ? userData.backgroundColor1
                  : "#25CB26"
              }
            ></EllipseCardOne>
            <EllipseCardTwo
              color={
                userData.backgroundColor2
                  ? userData.backgroundColor2
                  : "#25CB26"
              }
            ></EllipseCardTwo>
          </EllipseOuterContainer>
          <ProfileBlur>
            <CardinProfile>
              <ProfileLogos>
                <LogoCard src={LogoSmall} />
                <CustomLogo src={userData && userData.logo ? userData.logo: CustomLogo} />
              </ProfileLogos>
              <ImageContact>
                <ImageDash src={userData && userData.image ? userData.image: ImageCard} alt="This is an image" />
                <BorderDash src={Border} alt="This is the order" />
                <CardDetails>
                  <CardHeading
                    color={
                      userData.contentTextColour
                        ? userData.contentTextColour
                        : "#202020"
                    }
                  >
                    {userData && userData.firstname && userData.lastname
                      ? `${userData.firstname} ${userData.lastname}`
                      : "Your Name"}
                  </CardHeading>
                  <CardDescription
                    color={
                      userData.contentTextColour
                        ? userData.contentTextColour
                        : "#202020"
                    }
                  >
                    {userData && userData.designation
                      ? `${userData.designation}`
                      : "Designation"}
                  </CardDescription>
                  <CardSocialMedia>
                    <a
                      href={
                        userData.socialMedia
                          ? userData.socialMedia.facebook
                          : "www.facebook.com"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SocialMediaProfile src={Facebook} alt="social" />
                    </a>
                    <a
                      href={
                        userData.socialMedia
                          ? userData.socialMedia.linkedin
                          : "www.linkedin.com"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SocialMediaProfile src={LinkedIn} alt="social" />
                    </a>
                    <a
                      href={
                        userData.socialMedia
                          ? userData.socialMedia.twitter
                          : "www.linkedin.com"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SocialMediaProfile src={Twitter} alt="social" />
                    </a>
                    <a
                      href={
                        userData.socialMedia
                          ? userData.socialMedia.instagram
                          : "www.linkedin.com"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SocialMediaProfile src={Instagram} alt="social" />
                    </a>
                  </CardSocialMedia>
                </CardDetails>
              </ImageContact>
              <ShareProfileButtoninProfile>
                SHARE PROFILE
              </ShareProfileButtoninProfile>
            </CardinProfile>
          </ProfileBlur>
        </ProfileCard>

        {/* BUTTONS */}
        <CustomizedButtonsContainer>
          <CustomizedButtonBackgroundDiv>
            <ButtonEllipseColorOne
              color={
                userData.backgroundColor1
                  ? userData.backgroundColor1
                  : "#25CB26"
              }
            ></ButtonEllipseColorOne>
            <ButtonEllipseColorTwo
              color={
                userData.backgroundColor2
                  ? userData.backgroundColor2
                  : "#25CB26"
              }
            ></ButtonEllipseColorTwo>
          </CustomizedButtonBackgroundDiv>
          <ButtonBlur>
            <Icons>
              <IconsAndNumber>
                <SocialIcons src={Whatsapp} alt="whatsapp" />
                <SocialDetails
                  color={
                    userData.contentTextColour
                      ? userData.contentTextColour
                      : "#202020"
                  }
                >
                  {userData && userData.whatsappNo
                    ? `${userData.whatsappNo}`
                    : "+971 56 00 000"}
                </SocialDetails>
              </IconsAndNumber>
              <ArrowIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill={
                      userData.buttonColor1 ? userData.buttonColor1 : "#25CBB6"
                    }
                  />
                  <path d="M6.5 5L9.5 8L6.5 11" stroke="white" />
                  <defs>
                    <linearGradient
                      id="paint0_linear_208_14208"
                      x1="8"
                      y1="-4.5"
                      x2="6.5"
                      y2="24"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#25CBB6" />
                      <stop offset="1" stop-color="#045EE1" />
                    </linearGradient>
                  </defs>
                </svg>
              </ArrowIcon>
            </Icons>
          </ButtonBlur>
        </CustomizedButtonsContainer>

        <CustomizedButtonsContainer>
          <CustomizedButtonBackgroundDiv>
            <ButtonEllipseColorOne
              color={
                userData.backgroundColor1
                  ? userData.backgroundColor1
                  : "#25CB26"
              }
            ></ButtonEllipseColorOne>
            <ButtonEllipseColorTwo
              color={
                userData.backgroundColor2
                  ? userData.backgroundColor2
                  : "#25CB26"
              }
            ></ButtonEllipseColorTwo>
          </CustomizedButtonBackgroundDiv>
          <ButtonBlur>
            <Icons>
              <IconsAndNumber>
                <SocialIcons src={Phone} alt="whatsapp" />
                <SocialDetails
                  color={
                    userData.contentTextColour
                      ? userData.contentTextColour
                      : "#202020"
                  }
                >
                  {userData && userData.phoneNo
                    ? `${userData.phoneNo}`
                    : "+971 56 00 000"}
                </SocialDetails>
              </IconsAndNumber>
              <ArrowIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill={
                      userData.buttonColor1 ? userData.buttonColor1 : "#25CBB6"
                    }
                  />
                  <path d="M6.5 5L9.5 8L6.5 11" stroke="white" />
                  <defs>
                    <linearGradient
                      id="paint0_linear_208_14208"
                      x1="8"
                      y1="-4.5"
                      x2="6.5"
                      y2="24"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#25CBB6" />
                      <stop offset="1" stop-color="#045EE1" />
                    </linearGradient>
                  </defs>
                </svg>
              </ArrowIcon>
            </Icons>
          </ButtonBlur>
        </CustomizedButtonsContainer>

        <CustomizedButtonsContainer>
          <CustomizedButtonBackgroundDiv>
            <ButtonEllipseColorOne
              color={
                userData.backgroundColor1
                  ? userData.backgroundColor1
                  : "#25CB26"
              }
            ></ButtonEllipseColorOne>
            <ButtonEllipseColorTwo
              color={
                userData.backgroundColor2
                  ? userData.backgroundColor2
                  : "#25CB26"
              }
            ></ButtonEllipseColorTwo>
          </CustomizedButtonBackgroundDiv>
          <ButtonBlur>
            <Icons>
              <IconsAndNumber>
                <SocialIcons src={Website} alt="whatsapp" />
                <SocialDetails
                  color={
                    userData.contentTextColour
                      ? userData.contentTextColour
                      : "#202020"
                  }
                >
                  {userData && userData.company
                    ? `${userData.company}`
                    : "Company"}
                </SocialDetails>
              </IconsAndNumber>
              <ArrowIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill={
                      userData.buttonColor1 ? userData.buttonColor1 : "#25CBB6"
                    }
                  />
                  <path d="M6.5 5L9.5 8L6.5 11" stroke="white" />
                  <defs>
                    <linearGradient
                      id="paint0_linear_208_14208"
                      x1="8"
                      y1="-4.5"
                      x2="6.5"
                      y2="24"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#25CBB6" />
                      <stop offset="1" stop-color="#045EE1" />
                    </linearGradient>
                  </defs>
                </svg>
              </ArrowIcon>
            </Icons>
          </ButtonBlur>
        </CustomizedButtonsContainer>

        <CustomizedButtonsContainer>
          <CustomizedButtonBackgroundDiv>
            <ButtonEllipseColorOne
              color={
                userData.backgroundColor1
                  ? userData.backgroundColor1
                  : "#25CBB6"
              }
            ></ButtonEllipseColorOne>
            <ButtonEllipseColorTwo
              color={
                userData.backgroundColor2
                  ? userData.backgroundColor2
                  : "#25CBB6"
              }
            ></ButtonEllipseColorTwo>
          </CustomizedButtonBackgroundDiv>
          <ButtonBlur>
            <Icons>
              <IconsAndNumber>
                <SocialIcons src={Email} alt="whatsapp" />
                <SocialDetails
                  color={
                    userData.contentTextColour
                      ? userData.contentTextColour
                      : "#202020"
                  }
                >
                  {userData && userData.email
                    ? `${userData.email}`
                    : "example@gmail.com"}
                </SocialDetails>
              </IconsAndNumber>
              <ArrowIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle
                    cx="8"
                    cy="8"
                    r="8"
                    fill={
                      userData.buttonColor1 ? userData.buttonColor1 : "#25CBB6"
                    }
                  />
                  <path d="M6.5 5L9.5 8L6.5 11" stroke="white" />
                  <defs>
                    <linearGradient
                      id="paint0_linear_208_14208"
                      x1="8"
                      y1="-4.5"
                      x2="6.5"
                      y2="24"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#25CBB6" />
                      <stop offset="1" stop-color="#045EE1" />
                    </linearGradient>
                  </defs>
                </svg>
              </ArrowIcon>
            </Icons>
          </ButtonBlur>
        </CustomizedButtonsContainer>
        <SaveContact
          colorOne={userData.buttonColor1 ? userData.buttonColor1 : "#25CBB6"}
          colorTwo={userData.buttonColor2 ? userData.buttonColor2 : "#25CBB6"}
          color={userData.buttonTextColour ? userData.buttonTextColour : "#fff"}
        >
          SAVE CONTACT
        </SaveContact>
        <FooterDiv>
          <LogoFooter>
            <LogoBigFooter src={LogoSmall} />
          </LogoFooter>
          <HeadingsFooter>
            <MainFooterHeading>GET YOUR OWN</MainFooterHeading>
            <SecondaryFooterHeading>SMART CARD</SecondaryFooterHeading>
          </HeadingsFooter>
        </FooterDiv>
      </ProfileContainer>}
    </>
  );
};

export default UserProfile;
