import React from "react";
import {
  FooterContainer,
  FooterDesc,
  FooterDesignImage,
  FooterDiv,
  FooterImage,
  FooterImageDiv,
  FooterSocialImage,
  FooterSocialMedia,
} from "./Footer.styled";
import Logo from "../../assets/logo.svg";
import LeftRec from "../../assets/left_Rec.png";
import RightRec from "../../assets/right_Rec.png";
import Insta from "../../assets/insta.svg";
import LinkedIn from "../../assets/linkedin.svg";
import Twitter from "../../assets/twitter.svg";
import Facebook from "../../assets/facebook.svg";

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterDiv>
          <FooterImage src={Logo} alt="Logo" />
          <FooterDesc>
            Unlock Digital Excellence with Custom NFC Business Cards.
          </FooterDesc>
        </FooterDiv>
        <FooterImageDiv>
          <FooterDesignImage src={LeftRec} alt="rec design" />
          <FooterSocialMedia>
            <FooterSocialImage src={Insta} alt="social" />
            <FooterSocialImage src={LinkedIn} alt="social" />
            <FooterSocialImage src={Twitter} alt="social" />
            <FooterSocialImage src={Facebook} alt="social" />
          </FooterSocialMedia>
          <FooterDesignImage src={RightRec} alt="rec design" />
        </FooterImageDiv>
      </FooterContainer>
    </>
  );
};

export default Footer;
