import styled from "styled-components";

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: black;
  height: 310px;
  @media only screen and (max-width:585px){
    height: 230px;
    padding-bottom: 20px;
  }
`;

export const FooterDiv = styled.div`
  display: flex;
  width: 85%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const FooterImage = styled.img`
  height: 100px;
  width: 200px;
`;

export const FooterDesc = styled.p`
  color: #ddd;
  text-align: center;
  font-family: Roboto;
  font-size: 14.045px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.078px;
`;

export const FooterImageDiv = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width:585px){
    justify-content: center;
  }

`;

export const FooterDesignImage = styled.img`
  height: 52px;
  width: 588px;
  @media only screen and (min-width: 1300px) and (max-width: 1500px) {
    height: 40px;
    width: 530px;
  }
  @media only screen and (min-width: 1030px) and (max-width: 1300px) {
    height: 40px;
    width: 400px;
  }
  @media only screen and (max-width: 1030px) {
    height: 35px;
    width: 350px;
  }
  @media only screen and (max-width:900px){
    height: 30px;
    width: 300px;
  }
  @media only screen and (max-width:800px){
    height: 30px;
    width: 250px;
  }
  @media only screen and (max-width:700px){
    height: 30px;
    width: 200px;
  }
  @media only screen and (max-width:585px){
    display: none;
  }
`;

export const FooterSocialMedia = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

export const FooterSocialImage = styled.img`
  height: 30px;
  width: 30px;
`;
