import React, { useState } from "react";
import CardImage from "../../assets/authImage.svg";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import './Auth.css'
import Cookies from 'js-cookie';


import {
  HeadingPrimary,
  HeadingSecondary,
  LoginBlur,
  LoginContainerFirst,
  LoginCredentials,
  LoginEmailInput,
  LoginFormDiv,
  LoginImage,
  LoginImageMainDiv,
  LoginMainContainer,
  SubmitButton,
} from "./Auth.styled";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Auth = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await Axios.post(
        `https://api.weconnectify.com/api/authenticate`,
        {
          email,
          password,
        }
      );
  
      if (response.status === 200) {
        const { userId, token } = response.data;
        Cookies.set("token", token, { expires: 1 });
        navigate(`/account/${userId}`);
      } else {
        setError("User does not exist");
        handleOpen();
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setError("User does not exist");
      handleOpen();
    }
  };
  
  const snackbarStyle = {
    backgroundColor: "red",
    color: "white",
  };

  const action = (
    <React.Fragment>
      <Button color="error" size="small" onClick={handleClose}></Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      {error && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={error}
          action={action}
          ContentProps={{ style: snackbarStyle }}
        />
      )}
      <LoginContainerFirst>
        <LoginMainContainer>
          <LoginBlur>
            <LoginCredentials>
              <HeadingSecondary>ENTER YOUR</HeadingSecondary>
              <HeadingPrimary>CREDENTIALS</HeadingPrimary>
              <LoginFormDiv>
                <LoginEmailInput
                  placeholder="Email"
                  onChange={handleEmailChange}
                  value={email}
                />
                <LoginEmailInput
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <SubmitButton onClick={handleFormSubmit}>SEND</SubmitButton>
              </LoginFormDiv>
            </LoginCredentials>
            <LoginImageMainDiv>
              <LoginImage src={CardImage} alt="cardImage" />
            </LoginImageMainDiv>
          </LoginBlur>
        </LoginMainContainer>
      </LoginContainerFirst>
    </>
  );
};

export default Auth;
