import styled from "styled-components";

export const NavbarMainContainer = styled.div`
  background-color: black;
  width: 100%;
  z-index: 999;
  position: fixed;
`;

export const NavbarMain = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: black;
  padding: 20px 0px;
  width: 85%;
  margin: auto;

  @media only screen and (max-width: 768px) {
    padding: 20px 0px;
    width: 90%
  }
`;

export const NavbarMobile = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const LogoPart = styled.a`
  @media only screen and (max-width: 768px) {
    margin-right: 0px;
  }
`;

export const MobileBarsandCross = styled.div`
  display: none;
  align-items: center;
  margin-left: auto;
  width: 30px;
  @media only screen and (max-width: 768px) {
    display: block;
    align-items: center;
  }
`;

export const MobileMenuSymbols = styled.i`
  color: #fff;
  align-items: center;
  @media only screen and (max-width: 768px) {
    font-size: 26px;
    cursor: pointer;
  }
`;

export const MenuListForBigScreens = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 40px;
  }

  a {
    list-style: none;
  padding: 0 20px;
  position: relative;
  text-decoration: none;
  color: white;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 127%;
  letter-spacing: 2px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    color: #25cbb6;
  }
  }
`;

export const MenuListItems = styled.li`
  list-style: none;
  padding: 0 20px;
  position: relative;
  color: white;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 127%;
  letter-spacing: 2px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    color: #25cbb6;
  }

  &:active {
    content: "";
    width: 30%;
    height: 2px;
    background-color: #25cbb6;
    position: absolute;
    bottom: -4px;
    left: 20px;
  }
`;

export const MenuListItemsLogOut = styled.li`
  list-style: none;
  padding: 0 20px;
  position: relative;
  text-decoration: none;
  color: white;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 127%;
  letter-spacing: 2px;
  cursor: pointer;
 
  &:hover {
    color: #25cbb6;
  }


`;

export const MenuListLink = styled.a`
  text-decoration: none;
  color: white;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 127%;
  letter-spacing: 2px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    color: #25cbb6;
  }

  &:active {
    content: "";
    width: 30%;
    height: 2px;
    background-color: #25cbb6;
    position: absolute;
    bottom: -4px;
    left: 20px;
  }
`;



export const MenuListLinkLogoout = styled.p`
  text-decoration: none;
  color: white;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 127%;
  letter-spacing: 2px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    color: #25cbb6;
  }

  &:active {
    content: "";
    width: 30%;
    height: 2px;
    background-color: #25cbb6;
    position: absolute;
    bottom: -4px;
    left: 20px;
  }
`;

export const SideMenuContainer = styled.div`
  @media only screen and (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    position: fixed;
    top: 0;
    right: 0;
    width: 100%; /* Cover the entire screen width */
    height: 100vh;
    background-color: black;
    padding: 40px 20px 0 10px;
    z-index: 1000;
    transition: right 0.3s ease-in-out;
  }
`;
