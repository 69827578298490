import { Route, Routes } from "react-router-dom";
import Details from "./components/Details";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./pages/Dashboard";
import ProfilePage from "./pages/ProfilePage";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<LoginPage />} />
      <Route exact path="/account/:id" element={<Dashboard />} />
      <Route exact path="/profile/:id" element={<ProfilePage />} />
      <Route exact path="/detail/:id" element={<Details />} />
    </Routes>
  );
}

export default App;
