import React, { useState, Fragment, useEffect } from "react";
import { SketchPicker } from "react-color";
import axios from "axios";
import "./MyCard.css";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Cookies from "js-cookie";

import {
  AccountDiv,
  BorderDash,
  CardAndDetailsDiv,
  CardDescription,
  CardDetails,
  CardHeading,
  CardLogo,
  CardMainDiv,
  CardOnly,
  CardSocialMedia,
  DashboardBlur,
  DashboardContainerFirst,
  DashboardImageFirst,
  DashboardImageTwo,
  DetailDivMain,
  DetailsOnly,
  EllipseDiv,
  ImageContact,
  ImageDash,
  InnerFirstDiv,
  InnerSecondDiv,
  LargeInputField,
  LogoCard,
  LogoHeading,
  ParaDiv,
  ParagraphyProfile,
  PersonalInputDiv,
  PersonalInputField,
  ProfileLogoandImageDiv,
  ShareProfileButton,
  SmallInputField,
  SocialMedia,
  SubmitButton,
  TabButton,
  TabInnverdiv,
  TabMainDiv,
  UpdateButton,
  CustomFileInput,
  HiddenFileInput,
  YourDetails,
  ProfileBrandingDiv,
  ProfileOnly,
  FormOnly,
  DetailsForBranding,
  BackgroundColorOne,
  CheckboxWrapper,
  CheckboxInput,
  LabelMAIN,
  WrapperCheck,
  ColorDetails,
  ColorWrapper,
} from "./MyCard.styled";
import Border from "../../assets/border.png";
import ImageCard from "../../assets/MyImage.png";
import LogoSmall from "../../assets/logosmall.svg";
import Facebook from "../../assets/Group1.svg";
import LinkedIn from "../../assets/Group2.svg";
import Twitter from "../../assets/Group3.svg";
import Instagram from "../../assets/Group4.svg";
import Profile from "../Profile/Profile";

const MyCard = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Card");
  const [userData, setUserData] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [designation, setDesignation] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [instagram, setInstagram] = useState("");
  const [selectedImage, setSelectedImage] = useState(null); // To store selected image file
  const [logo, setLogo] = useState(null);
  const [fileName, setFileName] = useState(""); // To display the file name
  const [logoName, setLogoName] = useState(""); // To display the logo name
  const [buttonColorFour, setbackgroundColorTwo] = useState("");
  const [buttonColorOne, setbuttonColorOne] = useState("");
  const [buttonColorTwo, setbuttonColorTwo] = useState("");
  const [buttonColorThree, setbackgroundColor] = useState("");
  const [selectedOption, setSelectedOption] = useState("solid");
  const [selectedTextColor, setSelectedTextColor] = useState("black");
  const [selectedButtonColor, setSelectedButtonColor] = useState("white");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [open, setOpen] = useState(false);

  const handleProfileView = () => {
    navigate(`/profile/${params.id}`);
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="down" />;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleTextColorChange = (event) => {
    setSelectedTextColor(event.target.value);
  };

  const handleButtonColorChange = (event) => {
    setSelectedButtonColor(event.target.value);
  };

  const handlebackgroundColorChange = (color) => {
    setbackgroundColor(color.hex);
  };

  const handlebackgroundColorChangeTwo = (color) => {
    setbackgroundColorTwo(color.hex);
  };

  const handlebuttonColorChangeOne = (color) => {
    setbuttonColorOne(color.hex);
  };

  const handlebuttonColorChangeTwo = (color) => {
    setbuttonColorTwo(color.hex);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handlefirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleDesignationChange = (e) => {
    setDesignation(e.target.value);
  };

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleWhatsappChange = (e) => {
    setWhatsapp(e.target.value);
  };

  const handleFacebookChange = (e) => {
    setFacebook(e.target.value);
  };

  const handleLinkedinChange = (e) => {
    setLinkedin(e.target.value);
  };

  const handleTwitterChange = (e) => {
    setTwitter(e.target.value);
  };

  const handleInstagramChange = (e) => {
    setInstagram(e.target.value);
  };

  const handleImageChange = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setSelectedImage(reader.result);
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
    const selectedFile = e.target.files[0];
    setFileName(selectedFile ? selectedFile.name : "");
  };

  const handleLogoChange = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setLogo(reader.result);
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
    const selectedFile = e.target.files[0];
    setLogoName(selectedFile ? selectedFile.name : "");
  };

  const defaultFacebookUrl = "https://www.facebook.com/";
  const defaultLinkedInUrl = "https://linkedin.com/";
  const defaultInstagramUrl = "https://www.instagram.com/";
  const defaultTwitterUrl = "https://twitter.com/";

  let linkedinProfileUrl = defaultLinkedInUrl;
  let instagramProfileUrl = defaultInstagramUrl;
  let twitterProfileUrl = defaultTwitterUrl;
  let facebookProfileUrl = defaultFacebookUrl;

  if (userData) {
    if (userData.socialMedia && userData.socialMedia.linkedin) {
      linkedinProfileUrl = userData.socialMedia.linkedin;
    } else if (linkedin) {
      linkedinProfileUrl = linkedin;
    }

    if (userData.socialMedia && userData.socialMedia.instagram) {
      instagramProfileUrl = userData.socialMedia.instagram;
    } else if (instagram) {
      instagramProfileUrl = instagram;
    }

    if (userData.socialMedia && userData.socialMedia.twitter) {
      twitterProfileUrl = userData.socialMedia.twitter;
    } else if (twitter) {
      twitterProfileUrl = twitter;
    }

    if (userData.socialMedia && userData.socialMedia.facebook) {
      facebookProfileUrl = userData.socialMedia.facebook;
    } else if (facebook) {
      facebookProfileUrl = facebook;
    }
  }

  const handleEmailorPasswordChange = async () => {
    try {
      const userId = params.id;
      const token = Cookies.get("token");
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);
      const response = await axios.put(
        `https://api.weconnectify.com/api/updateEmailorPassword/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        setSuccess("Email or password updated");
        setEmail("")
        setPassword("")
        handleOpen();
      } else {
        navigate("/");
        setError("Please login again");
        handleOpen();
      }
    } catch {
      console.error("Error:", error);
      navigate("/");
      setError("Please try again");
      handleOpen();
    }
  };

  const handleSaveBranding = async () => {
    try {
      const userId = params.id;
      const token = Cookies.get("token");
      const formData = new FormData();
      formData.append("backgroundColor1", buttonColorThree);
      formData.append("backgroundColor2", buttonColorFour);
      formData.append("buttonColor1", buttonColorOne);
      formData.append("buttonColor2", buttonColorTwo);
      formData.append("buttonTextColour", selectedButtonColor);
      formData.append("contentTextColour", selectedTextColor);
      formData.append("brandingOption", selectedOption);

      formData.append("logo", logo);
      const response = await axios.put(
        `https://api.weconnectify.com/api/updateOrCreateProfile/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        setSuccess("The Profile has been saved succesfully!");
        handleOpen();
      } else {
        navigate("/");
        setError("Please try again");
        handleOpen();
      }
    } catch (error) {
      console.error("Error:", error);
      navigate("/");
      setError("Please try again");
      handleOpen();
    }
  };
  const ErrorStyle = {
    backgroundColor: "red",
    color: "white",
  };
  const SuccessStyle = {
    backgroundColor: "green",
    color: "white",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const orderId = params.id;
    const token = Cookies.get("token");
    const formData = new FormData();
    formData.append("firstname", firstName);
    formData.append("lastname", lastName);
    formData.append("designation", designation);
    formData.append("company", company);
    formData.append("phoneNo", phone);
    formData.append("whatsappNo", whatsapp);
    formData.append("facebook", facebook);
    formData.append("twitter", twitter);
    formData.append("linkedin", linkedin);
    formData.append("instagram", instagram);
    formData.append("image", selectedImage);

    try {
      // Send a POST request to backend API
      const response = await axios.put(
        `https://api.weconnectify.com/api/updateOrCreateProfile/${orderId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        setSuccess("The Profile has been saved!");
        setFirstName("");
        setLastName("");
        setDesignation("");
        setCompany("");
        setPhone("");
        setWhatsapp("");
        setFacebook("");
        setTwitter("");
        setLinkedin("");
        setInstagram("");
        setSelectedImage(null);
        setFileName("");
        handleOpen();
      }

      if (response.status === 401) {
        navigate("/");
        setError("Please try again");
        handleOpen();
      }

      if (response.status === 403) {
        navigate("/");
        setError("Unauthorized Access");
        handleOpen();
      }
      if (response.status === 500) {
        setError("Internal server error");
        handleOpen();
      }
      if (response.status === 404) {
        navigate("/");
        setError("User not found");
        handleOpen();
      } else {
        setError("Please try again");
        handleOpen();
      }
    } catch (error) {
      console.error("Error:", error);
      navigate("/");
      setError("Please try again");
      handleOpen();
    }
  };

  useEffect(() => {
    const handleUserData = async () => {
      const userId = params.id;
      const token = Cookies.get("token");

      try {
        const response = await axios.get(
          `https://api.weconnectify.com/api/userdetail/${userId}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const userData = response.data;
          setUserData(userData);
        } else {
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    handleUserData();
  }, []);

  const action = (
    <React.Fragment>
      <Button color="error" size="small" onClick={handleClose}></Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" onClick={handleClose} />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <DashboardContainerFirst>
        {error && (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={error}
            action={action}
            ContentProps={{ style: ErrorStyle }}
            TransitionComponent={TransitionLeft}
          />
        )}
        {success && (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={success}
            action={action}
            ContentProps={{ style: SuccessStyle }}
            TransitionComponent={TransitionLeft}
          />
        )}
        <EllipseDiv>
          <DashboardImageTwo></DashboardImageTwo>
          <DashboardImageFirst></DashboardImageFirst>
        </EllipseDiv>
        <DashboardBlur>
          <InnerFirstDiv>
            <InnerSecondDiv>
              <TabMainDiv>
                <TabInnverdiv>
                  <TabButton
                    active={activeTab === "Card"}
                    onClick={() => handleTabClick("Card")}
                  >
                    Card
                  </TabButton>
                  <TabButton
                    active={activeTab === "Account"}
                    onClick={() => handleTabClick("Account")}
                  >
                    Account
                  </TabButton>
                  <TabButton
                    active={activeTab === "Branding"}
                    onClick={() => handleTabClick("Branding")}
                  >
                    Branding
                  </TabButton>
                </TabInnverdiv>
              </TabMainDiv>
              {activeTab === "Card" && (
                <CardAndDetailsDiv>
                  <CardOnly>
                    <CardMainDiv>
                      <CardLogo>
                        <LogoCard src={LogoSmall} />
                      </CardLogo>
                      <ImageContact>
                        <ImageDash
                          src={selectedImage ? selectedImage : ImageCard}
                          alt="This is an image"
                        />
                        <BorderDash src={Border} alt="This is the order" />
                        <CardDetails>
                          <CardHeading>
                            {firstName || "Your"} {lastName || "Name"}
                          </CardHeading>

                          <CardDescription>
                            {designation || "Designation"}
                          </CardDescription>
                          <CardSocialMedia>
                            <a
                              href={facebookProfileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <SocialMedia src={Facebook} alt="social" />
                            </a>
                            <a
                              href={linkedinProfileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <SocialMedia src={LinkedIn} alt="social" />
                            </a>
                            <a
                              href={twitterProfileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <SocialMedia src={Twitter} alt="social" />
                            </a>
                            <a
                              href={instagramProfileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <SocialMedia src={Instagram} alt="social" />
                            </a>
                          </CardSocialMedia>
                        </CardDetails>
                      </ImageContact>
                      <ShareProfileButton onClick={handleProfileView}>
                        VIEW PROFILE
                      </ShareProfileButton>
                    </CardMainDiv>
                    <ParaDiv>
                      <ParagraphyProfile>
                        You can see the preview of your profile card once you
                        hit update button
                      </ParagraphyProfile>
                    </ParaDiv>
                  </CardOnly>
                  <DetailsOnly>
                    <YourDetails>YOUR DETAILS</YourDetails>
                    <DetailDivMain>
                      <SmallInputField
                        placeholder="FIRST NAME"
                        onChange={handlefirstNameChange}
                        value={firstName}
                      />
                      <SmallInputField
                        placeholder="LAST NAME"
                        onChange={handleLastNameChange}
                        value={lastName}
                      />
                      <SmallInputField
                        placeholder="DESIGNATION"
                        onChange={handleDesignationChange}
                        value={designation}
                      />
                      <SmallInputField
                        placeholder="COMPANY"
                        onChange={handleCompanyChange}
                        value={company}
                      />
                      <SmallInputField
                        placeholder="PHONE NO"
                        onChange={handlePhoneChange}
                        value={phone}
                      />
                      <SmallInputField
                        placeholder="WHATSAPP NO"
                        onChange={handleWhatsappChange}
                        value={whatsapp}
                      />

                      <ProfileLogoandImageDiv>
                        <LogoHeading>Upload Your Image</LogoHeading>
                        <CustomFileInput>
                          <span
                            style={{
                              fontFamily: "Montserrat",
                              fontSize: "12.23px",
                              fontWeight: "normal",
                              color: "#303030",
                              paddingLeft: "15px",
                            }}
                          >
                            {fileName ||
                              "Drag and drop your files here to Upload or click to browse"}
                          </span>
                          <HiddenFileInput
                            type="file"
                            accept="image/"
                            onChange={handleImageChange}
                          />
                        </CustomFileInput>
                      </ProfileLogoandImageDiv>

                      <LargeInputField
                        placeholder="Enter your facebook profile url"
                        onChange={handleFacebookChange}
                        value={facebook}
                      />
                      <LargeInputField
                        placeholder="Enter your instagram profile url"
                        onChange={handleInstagramChange}
                        value={instagram}
                      />
                      <LargeInputField
                        placeholder="Enter your twitter profile url"
                        onChange={handleTwitterChange}
                        value={twitter}
                      />
                      <LargeInputField
                        placeholder="Enter your linkedin profile url"
                        onChange={handleLinkedinChange}
                        value={linkedin}
                      />
                      <SubmitButton onClick={handleSubmit}>SUBMIT</SubmitButton>
                    </DetailDivMain>
                  </DetailsOnly>
                </CardAndDetailsDiv>
              )}

              {activeTab === "Account" && (
                <AccountDiv>
                  <YourDetails>PERSONAL DETAILS</YourDetails>
                  <PersonalInputDiv>
                    <PersonalInputField
                      placeholder="Update your email"
                      onChange={handleEmailChange}
                      value={email}
                    />
                    <UpdateButton onClick={handleEmailorPasswordChange}>Update</UpdateButton>
                  </PersonalInputDiv>

                  <PersonalInputDiv>
                    <PersonalInputField
                      placeholder="Update your password"
                      onChange={handlePasswordChange}
                      value={password}
                    />
                    <UpdateButton onClick={handleEmailorPasswordChange}>Update</UpdateButton>
                  </PersonalInputDiv>
                </AccountDiv>
              )}
              {activeTab === "Branding" && (
                <ProfileBrandingDiv>
                  <ProfileOnly>
                    <Profile
                      background1={buttonColorThree}
                      background2={buttonColorFour}
                      button1={buttonColorOne}
                      button2={buttonColorTwo}
                      logo={logo}
                      option={selectedOption}
                      contenttext={selectedTextColor}
                      buttontext={selectedButtonColor}
                      userData={userData}
                      linkedin={linkedin}
                      instagram={instagram}
                      twitter={twitter}
                      facebook={facebook}
                    />
                  </ProfileOnly>
                  <FormOnly>
                    <DetailsForBranding>
                      <YourDetails>CHOOSE BRANDING COLORS</YourDetails>

                      {/* COLOR OPTIONS */}
                      <CheckboxWrapper>
                        <WrapperCheck>
                          <CheckboxInput
                            value="solid"
                            checked={selectedOption === "solid"}
                            onChange={handleOptionChange}
                          />
                          <LabelMAIN>Solid</LabelMAIN>
                        </WrapperCheck>
                        <WrapperCheck>
                          <CheckboxInput
                            value="gradient"
                            checked={selectedOption === "gradient"}
                            onChange={handleOptionChange}
                          />
                          <LabelMAIN>Gradient</LabelMAIN>
                        </WrapperCheck>
                      </CheckboxWrapper>

                      {/* For TEXT COLOR  */}
                      <ColorWrapper>
                        <ColorDetails>CHOOSE CONTENT TEXT COLORS</ColorDetails>
                        <CheckboxWrapper>
                          <WrapperCheck>
                            <CheckboxInput
                              value="white"
                              checked={selectedTextColor === "white"}
                              onChange={handleTextColorChange}
                            />
                            <LabelMAIN>White</LabelMAIN>
                          </WrapperCheck>
                          <WrapperCheck>
                            <CheckboxInput
                              value="black"
                              checked={selectedTextColor === "black"}
                              onChange={handleTextColorChange}
                            />
                            <LabelMAIN>Black</LabelMAIN>
                          </WrapperCheck>
                        </CheckboxWrapper>
                      </ColorWrapper>

                      {/* For BUTTON COLOR  */}
                      <ColorWrapper>
                        <ColorDetails>CHOOSE BUTTON TEXT COLORS</ColorDetails>
                        <CheckboxWrapper>
                          <WrapperCheck>
                            <CheckboxInput
                              value="white"
                              checked={selectedButtonColor === "white"}
                              onChange={handleButtonColorChange}
                            />
                            <LabelMAIN>White</LabelMAIN>
                          </WrapperCheck>
                          <WrapperCheck>
                            <CheckboxInput
                              value="black"
                              checked={selectedButtonColor === "black"}
                              onChange={handleButtonColorChange}
                            />
                            <LabelMAIN>Black</LabelMAIN>
                          </WrapperCheck>
                        </CheckboxWrapper>
                      </ColorWrapper>

                      {/* For COLOR PICKER  */}
                      <DetailDivMain>
                        <BackgroundColorOne>
                          <LogoHeading>Choose Background Color#1</LogoHeading>
                          <SketchPicker
                            color={buttonColorThree}
                            onChange={handlebackgroundColorChange}
                          />
                        </BackgroundColorOne>

                        {selectedOption === "gradient" && (
                          <BackgroundColorOne>
                            <LogoHeading>Choose Background Color#2</LogoHeading>
                            <div
                              style={{ height: "400px", overflow: "hidden" }}
                            >
                              <SketchPicker
                                color={buttonColorFour}
                                onChange={handlebackgroundColorChangeTwo}
                              />
                            </div>
                          </BackgroundColorOne>
                        )}

                        <BackgroundColorOne>
                          <LogoHeading>Choose Button Color#1</LogoHeading>
                          <SketchPicker
                            color={buttonColorOne}
                            onChange={handlebuttonColorChangeOne}
                          />
                        </BackgroundColorOne>
                        {selectedOption === "gradient" && (
                          <BackgroundColorOne>
                            <LogoHeading>Choose Button Color#2</LogoHeading>
                            <SketchPicker
                              color={buttonColorTwo}
                              onChange={handlebuttonColorChangeTwo}
                            />
                          </BackgroundColorOne>
                        )}

                        <ProfileLogoandImageDiv>
                          <LogoHeading>Upload Your Logo</LogoHeading>
                          <CustomFileInput>
                            <span
                              style={{
                                fontFamily: "Montserrat",
                                fontSize: "12.23px",
                                fontWeight: "normal",
                                color: "#303030",
                                paddingLeft: "15px",
                              }}
                            >
                              {logoName ||
                                "Drag and drop your files here to Upload or click to browse"}
                            </span>
                            <HiddenFileInput
                              type="file"
                              accept="image/"
                              onChange={handleLogoChange}
                            />
                          </CustomFileInput>
                        </ProfileLogoandImageDiv>
                        <SubmitButton onClick={handleSaveBranding}>
                          SAVE BRANDING
                        </SubmitButton>
                      </DetailDivMain>
                    </DetailsForBranding>
                  </FormOnly>
                </ProfileBrandingDiv>
              )}
            </InnerSecondDiv>
          </InnerFirstDiv>
        </DashboardBlur>
      </DashboardContainerFirst>
    </>
  );
};

export default MyCard;
