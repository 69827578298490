import React, { useState } from "react";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import {
  LogoPart,
  MenuListForBigScreens,
  MenuListItemsLogOut,
  MobileBarsandCross,
  MobileMenuSymbols,
  NavbarMain,
  NavbarMainContainer,
  NavbarMobile,
  SideMenuContainer,
} from "./Navbar.styled";

import axios from "axios";
import { useNavigate } from "react-router-dom";

const Navbar = ({ display }) => {
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();
  const weconnectifyLogOut = async () => {
    try {
      console.log("THe function is working");
      const response = await axios.get("https://api.weconnectify.com/api/logout");
      console.log(response.data, response.status);

      if (response.status === 200) {
        navigate("/");
      } else {
        console.error("Logout failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
      console.log("There is some error", error);
    }
  };

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <NavbarMainContainer>
      <NavbarMain>
        <NavbarMobile>
          <LogoPart href="/">
            <Logo />
          </LogoPart>
          <MobileBarsandCross onClick={handleClick}>
            {clicked ? (
              <MobileMenuSymbols className="fas fa-times"></MobileMenuSymbols>
            ) : (
              <MobileMenuSymbols className="fas fa-bars"></MobileMenuSymbols>
            )}
          </MobileBarsandCross>
        </NavbarMobile>
        <SideMenuContainer isOpen={clicked}>
          <MobileBarsandCross onClick={handleClick}>
            <MobileMenuSymbols className="fas fa-times"></MobileMenuSymbols>
          </MobileBarsandCross>
          <MenuListForBigScreens>
            <a href="https://weconnectify.com" target="blank">Shop</a>
            <a href="https://weconnectify.com/about" target="blank" >About</a>
            <a href="https://weconnectify.com/contact" target="blank">Contact</a>

            {display === "block" && <MenuListItemsLogOut onClick={weconnectifyLogOut}>
              Logout
            </MenuListItemsLogOut>}
          </MenuListForBigScreens>
        </SideMenuContainer>
      </NavbarMain>
    </NavbarMainContainer>
  );
};

export default Navbar;
